import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";

import {
  type NfcHashValidationAction,
  NfcHashValidationResponseErrorType,
  postNfcHashValidationErrorResponseSchema,
  postNfcHashValidationRequestSchema,
  postNfcHashValidationResponseSchema,
} from "./types";

export interface UseNfcHashValidationProps {
  shiftId: string;
  hashString: string;
  action: NfcHashValidationAction;
}

export enum NfcHashValidationResult {
  SUCCESS = "success",
  INVALID_HASH = "invalid-hash",
  INCORRECT_HASH = "incorrect-hash",
  INACTIVE_HASH = "inactive-hash",
  HASH_NOT_OF_FACILITY = "hash-not-of-facility",
  OTHER_FAILURE = "other-failure",
  NETWORK_ERROR = "network-error",
}

const mappingNfcHashValidationResponseErrorToResult: Record<
  NfcHashValidationResponseErrorType,
  NfcHashValidationResult
> = {
  [NfcHashValidationResponseErrorType.INVALID_HASH]: NfcHashValidationResult.INVALID_HASH,
  [NfcHashValidationResponseErrorType.INCORRECT_HASH]: NfcHashValidationResult.INCORRECT_HASH,
  [NfcHashValidationResponseErrorType.INACTIVE_HASH]: NfcHashValidationResult.INACTIVE_HASH,
  [NfcHashValidationResponseErrorType.OTHER_FAILURE]: NfcHashValidationResult.OTHER_FAILURE,
  [NfcHashValidationResponseErrorType.HASH_NOT_OF_FACILITY]:
    NfcHashValidationResult.HASH_NOT_OF_FACILITY,
};

function convertNfcHashValidationErrorToResult(error: string): NfcHashValidationResult | undefined {
  if (error in mappingNfcHashValidationResponseErrorToResult) {
    return mappingNfcHashValidationResponseErrorToResult[
      error as NfcHashValidationResponseErrorType
    ];
  }

  return undefined;
}

async function sendNfcHashValidationRequest(
  props: UseNfcHashValidationProps
): Promise<NfcHashValidationResult> {
  const { shiftId, hashString, action } = props;

  try {
    const response = await post({
      url: `${environmentConfig.REACT_APP_BASE_API_URL}/nfc-hash-validations`,
      data: {
        data: {
          type: "nfc-hash-validation",
          attributes: {
            action,
            hash: hashString,
          },
          relationships: {
            shift: { data: { type: "shift", id: shiftId } },
          },
        },
      },
      requestSchema: postNfcHashValidationRequestSchema,
      responseSchema: postNfcHashValidationResponseSchema,
    });

    const validationId = response.data?.data?.id;

    if (!validationId) {
      logError(APP_V2_APP_EVENTS.NFC_HASH_VALIDATION_ERROR, {
        error: "Unknown NFC validation error: missing validation ID",
        metadata: {
          shiftId,
          hashString,
          action,
          response,
          validationId,
        },
      });

      return NfcHashValidationResult.OTHER_FAILURE;
    }

    return NfcHashValidationResult.SUCCESS;
  } catch (error: unknown) {
    if (!(error instanceof AxiosError)) {
      logError(APP_V2_APP_EVENTS.NFC_HASH_VALIDATION_ERROR, {
        error: "Unknown NFC validation error: unknown error",
        metadata: { shiftId, hashString, action, error },
      });
      return NfcHashValidationResult.OTHER_FAILURE;
    }

    if (!error.response) {
      logError(APP_V2_APP_EVENTS.NFC_HASH_VALIDATION_ERROR, {
        error: "Unknown NFC validation error: network error",
        metadata: { shiftId, hashString, action, error },
      });
      return NfcHashValidationResult.NETWORK_ERROR;
    }

    const validationResult = postNfcHashValidationErrorResponseSchema.safeParse(
      error.response?.data as unknown
    );
    if (!validationResult.success) {
      logError(APP_V2_APP_EVENTS.NFC_HASH_VALIDATION_ERROR, {
        error: "Unknown NFC validation error: invalid JSON API error",
        metadata: {
          shiftId,
          hashString,
          action,
          errorResponse: error.response,
          errorResponseData: error.response?.data,
          validationError: validationResult.error,
        },
      });
      return NfcHashValidationResult.OTHER_FAILURE;
    }

    const errorResponse = validationResult.data;
    const matchingKnownErrorType = convertNfcHashValidationErrorToResult(
      errorResponse.errors[0].code
    );
    if (matchingKnownErrorType) {
      return matchingKnownErrorType;
    }

    logError(APP_V2_APP_EVENTS.NFC_HASH_VALIDATION_ERROR, {
      error: "Unknown NFC validation error: unknown error type",
      metadata: {
        shiftId,
        hashString,
        action,
        errorResponse: error.response,
        errorResponseData: error.response?.data,
        responseErrorCode: errorResponse.errors[0].code,
        matchingKnownErrorType,
      },
    });

    return NfcHashValidationResult.OTHER_FAILURE;
  }
}

export function useNfcHashValidation(): UseMutationResult<
  NfcHashValidationResult,
  unknown,
  UseNfcHashValidationProps
> {
  return useMutation({
    mutationFn: sendNfcHashValidationRequest,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.NFC_HASH_VALIDATION_REQUEST_FAILED,
      logSuccessMessage: APP_V2_APP_EVENTS.NFC_HASH_VALIDATION_REQUEST_SUCCEEDED,
    },
  });
}
